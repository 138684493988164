// Import Component hooks before component definitions
import "./component-hooks";
import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/vee-validate";
import App from "./App.vue";
import store from "@/store";
import router from "@/router";
import "./registerServiceWorker";
import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify";
import updateToken from "@/middlewares/update-token";
import { keycloakUrl, keycloakRealm, appUrl, environment } from "@/env";
import Keycloak from "keycloak-js";

store.commit("setEnvironment", environment);

Vue.config.productionTip = false;

export const logoPath = "@/assets/img/passepartout/logo.svg";


const keycloak = new Keycloak({
  realm: keycloakRealm,
  url: keycloakUrl + '/auth',
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
});

keycloak.init({
  onLoad: "check-sso",
  pkceMethod: "S256",
  checkLoginIframe: false,
}).then(async (authenticated) => {
  store.commit("setKeycloak", keycloak)
  Vue.prototype.$keycloak = keycloak;
  Vue.prototype.$keycloak.authenticated = authenticated;
  
  if (authenticated) {
    await store.dispatch("getUserProfileOrCreate");
  }
  new Vue({
    vuetify: new Vuetify(),
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}).catch((error) => {
  console.error("Keycloak Init Error:", error)
});
  