<template>
  <div id="app">
    <v-app>
      <router-view />
      <NotificationsManager></NotificationsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@/components/NotificationsManager.vue";
import updateToken from "@/middlewares/update-token";

@Component({
  components: {
    NotificationsManager,
  },
  watch: {
    $route() {
      updateToken();
    },
  },
})
export default class App extends Vue {}
</script>
